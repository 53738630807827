.layout {
  min-height: 100%;
}

.header {
  display: flex;
  align-items: center;
  gap: 16px;
}

.menu {
  flex: 1;
}

.content {
  padding: 24px;
}

.footer {
  text-align: center;
}

.pageHeader {
  margin-bottom: 24px;
}
